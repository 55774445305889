/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { Box, Checkbox, FormControlLabel, Grid, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { useLazyQuery, useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { selectUserDetails } from '../../store/user/user.selectors';
import { CLEAR_USER_SESSION, UPDATE_CONSENT_DETAILS, GET_SSO_USERDETAILS } from '../../graphql/Queries/UserDetailsLogin';
import { store } from '../../store';
import { userUpdateDetails } from '../../store/user/user.actions';
import './TermsModal.scss';
import 'remixicon/fonts/remixicon.css';
import { getTermsCondsContentResponse } from '../../models/api/getTermsData';
import { GET_TERMS_DATA } from '../../graphql/Queries/getTermsDetails';
import DOMPurify from 'dompurify';
import primaryConnectLogo from './../../assets/Primary-Connect-Plus-Logo.svg';

const TermsModal = ({ view }: { view: string}) => {
  const [terms, setTerms] = useState<string>('');
  const history = useHistory();
  const { data: termsData } = useQuery<getTermsCondsContentResponse>(GET_TERMS_DATA);

  const [clearUserSession, { data: logoutSuccess }] = useLazyQuery(CLEAR_USER_SESSION);
  const [updateUserConsent, { data: consentUpdateSuccess }] = useLazyQuery(UPDATE_CONSENT_DETAILS);
  const [getSsoLoginDetails, { data: userDetailFromGraphQL }] = useLazyQuery(GET_SSO_USERDETAILS, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only"
  });
  const userDetails = useSelector(selectUserDetails);
  const [checkboxSelected, setCheckboxSelected] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const checkBx = useRef() as React.MutableRefObject<HTMLDivElement>;

  const logoutAction = () => {
    clearUserSession();
  }

  const termsAction = () => {
    setShowWarning(false);
    setCheckboxSelected(!checkboxSelected);
  }

  const agreeAction = () => {
    if (!checkboxSelected) {
      setShowWarning(true);
      document?.getElementById('content')?.scrollIntoView();
    } else {
      updateUserConsent();
    }
  }

  useEffect(() => {
    if (showWarning) {
      checkBx.current.focus();
    }
  }, [showWarning]);

  useEffect(() => {
    if (logoutSuccess) {
      if (userDetails?.user?.roletype === 'Internal') {
        window.location.href = process.env.REACT_APP_LOGOUT_SSO_URL ?? '';
      }
      else {
        window.location.href = process.env.REACT_APP_EXTERNAL_LOGOUT_SSO_URL ?? '';
      }
    }
  }, [logoutSuccess, userDetails?.user?.roletype]);

  useEffect(() => {
    if (consentUpdateSuccess) {
      getSsoLoginDetails();
    }
  }, [consentUpdateSuccess, getSsoLoginDetails]);

  useEffect(() => {
    if (userDetailFromGraphQL) {
      if (userDetailFromGraphQL?.getUserdetails?.userdetails) {
        store.dispatch(userUpdateDetails((userDetailFromGraphQL?.getUserdetails)));
      }
      else {
        history.push('/login')
      }
    }
  }, [history, userDetailFromGraphQL]);

  useEffect(() => {
    setTerms(DOMPurify.sanitize(termsData?.getTermsCondsContent ?? '', {
      FORCE_BODY: true,
    }))
  }, [termsData]);

  return (
    <Grid container className="terms-main" data-testid="term-page">
      <Grid item  xs={12} sm={12} md={12} lg={6} xl={6} >
        {userDetails?.user?.isconsentupdatedtime ? (
          <Box mb={4} bgcolor="warning.main" p={2} className={`terms-change-alert ${view}`}>
            <Box display='flex' justifyContent='space-between'>
              <i className="ri-alert-fill alert-icon" />
              <Box pl={2} flexGrow={1}>
                <Typography variant="subtitle2" color="textPrimary">Your terms and conditions have been changed.</Typography>
                <Box pt={1}>
                  <Typography variant="body2" color="textPrimary">Please review and accept the new terms and conditions. If you already did it, you can accept and dismiss this message.</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        ): ''}
        <Box className="terms-grid">
          <Box className="terms-header" >
            <Typography variant="h1" data-testid='primary-connect'>Terms and Conditions</Typography>
            <img className="header-image" src={primaryConnectLogo} alt="Logo" />
          </Box>
          <Box className="terms-content">
            <Box className="content">
              <div dangerouslySetInnerHTML={{ __html: terms }} />
              <>
                <div className="termsAgreed">
                  <FormControlLabel control={<Checkbox id='content' className={`${showWarning ? "check-message" : ""}`} onChange={termsAction} color="primary" data-testid="my-checkbox" />} label={<Typography variant="body1" color="textPrimary" component="span">I have read and accepted the Terms and Conditions</Typography>} />
                </div>
                {showWarning &&
                  <div className="termsAgreedError" ref={checkBx} data-testid="term-error">
                    <i className='ri-error-warning-fill' />
                    <Typography variant="body1" color="error" component="span">Please accept the Terms and Conditions</Typography>
                  </div>}
              </>
              <Box height="20px" />
            </Box>
          </Box>
          <Box className="terms-footer" >
              <Button color='secondary' variant='contained'data-testid='cancel-button' onClick={logoutAction}>
                Close
              </Button>
              <Button color="primary" variant='contained' data-testid='allow-button' onClick={agreeAction} >
                Accept
              </Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}

export default TermsModal;