/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import './MaintenancePage.scss';
import Logo from '../../assets/Primary-Connect-Plus-Logo.svg'
import Truck from '../../assets/Primary_Connect_Truck.png'
import { Box, Typography } from '@material-ui/core';
import { PageTitle } from '../../models/page-title';

function MaintenancePage() {
    document.title = PageTitle.MAINTENANCEPAGE;
    return (
        <Box className="maintenance-page" data-testid='maintenance-page'>
            <Box><img className="primary-connect-logo" alt='Primary connect logo' src={Logo} /></Box>
            <Box><img className="primary-connect-truck" alt='Primary connect truck' src={Truck} /></Box>
            <Box><Typography variant="h3">We’re making some improvements</Typography></Box>
            <Box className='maintenance-msg'>
                {process.env.REACT_APP_MAINTENANCE_MESSAGE
                    ? <Typography variant="body1">{process.env.REACT_APP_MAINTENANCE_MESSAGE}</Typography>
                    : <Typography variant="body1">The site currently down for maintenance. We’ll be back online shortly.</Typography>
                }
            </Box>
            <Box className='maintenance-contact'>
                <Typography variant="body2">Contact us by phone <a href="#">1800 PRIMARY</a> or email <a href="mailto:info@primaryconnect.com.au">info@primaryconnect.com.au</a></Typography>
            </Box>
        </Box>
    );

}
export default MaintenancePage;