import React, { useState , useEffect, useMemo } from 'react';
import { BrowserRouter, Route, Switch, Redirect, useLocation } from 'react-router-dom';
import Header from './components/Header/Header';
import Dashboard from './components/Dashboard/Dashboard';
import Footer from './components/Footer/Footer';
import { FetchOrderApp } from './MicroFrontend/FetchOrderApp';
import { CreateEPodApp } from './MicroFrontend/CreateEPodApp';
import { LandingApp } from './MicroFrontend/CreateLandingApp';
import { client } from './graphql/apollo-client/ApolloClient';
import { PrePlanning } from './components/Orders';
import { AlertProvider } from './contexts/alert-context';
import './App.scss';
import ErrorPage from './components/ErrorPage/ErrorPage';
import TermsModal from './components/TermsModal/TermsModal';
import Navigation from './components/NavBar/Navigation';
import { useWindowEventListener } from './events/useWindowEventListener';
import { eventsToListen } from './events/events';
import { Box, createGenerateClassName, StylesProvider } from '@material-ui/core';
import { Provider, useSelector } from 'react-redux';
import { store } from './store';
import { createOrderSuccess , updateOrderSuccess } from './store/order/order.actions';
import { resetSessionTimer, updateSeqFieldMapping, userUpdateDetails } from './store/user/user.actions';
import { getCustomerDetails, getIsCarrierMapped, selectUserDetails } from './store/user/user.selectors';
import { useHistory } from "react-router-dom";
import { useLazyQuery } from '@apollo/client';
import { GET_SSO_USERDETAILS } from './graphql/Queries/UserDetailsLogin';
import { Theme } from 'csp-common-ui';
import { ThemeProvider } from '@material-ui/core';
import { ApolloProvider } from '@apollo/client';
import CircularProgress from '@material-ui/core/CircularProgress';
import 'remixicon/fonts/remixicon.css';
import { GAinit, trackLoginAnalytics } from './analytics/analytics';
import MaintenancePage from './components/MaintenancePage/MaintenancePage';
import ErrorUrl from './components/ErrorUrl/ErrorUrl';
import { GetUserDetailsResponse } from './models/api/get-sso-userdetails';
import { GetDefaultUrl } from './components/utils/helper';
import { FetchAdminApp } from './MicroFrontend/FetchAdminApp';
import UnauthorisedAccess from './components/UnauthorisedAccess/Unauthorised';
import SessionAlert from './components/sessionAlert/session-alert';
import Banner from './components/Banner/banner';
import CarrierCustomerNotMapped from './components/common/not-mapped-error-modal';
import TabMenu from './components/UserProfilePage/TabMenu';
import { PreferenceTypes } from './models/preference-type-mapping';
import EventHandler from './components/EventHandler/EventHandler';
import ErrorPageRedirect from './components/ErrorPageRedirect/ErrorPageRedirect';
import { LicenseInfo } from '@mui/x-license-pro';
import TermPage from './components/TermPage/Termpage';
import Feedback from './components/Feedback/feedBack';
import { CreateLoadApp } from './MicroFrontend/CreadLoadApp';
import { SwitchServiceTypeInterface } from './components/switchServiceType/switch-service-type';
import Cookies from 'universal-cookie';
import { RoleType } from './models/user-roles';
import { SwitchAccount } from './components/switchAccount/switch-account';
import { ServiceTypes } from './models/service-types';
import { FetchGatehouseApp } from './MicroFrontend/FetchGatehouseApp';
import { GetFiedsByScreenIDResponse } from './models/api/get-field-by-screen-id';
import { GET_ALL_FIELD_MASTER } from './graphql/Queries/getAllFieldMaster';
import { setPrefFields, setUserDetails } from './components/utils/windowHelper';
import { CreateDcAdminApp } from './MicroFrontend/CreadDcAdminApp';
import Unsubscribe from './components/Unsubscribe/unsubscribe';
import { getAccountManagementRedirect } from './components/utils/redirects';
// import CustomerDashboard from './components/Dashboard/customer-dashboard';

const generateClassName = createGenerateClassName({
  productionPrefix: 'co',
  seed: 'co'
});

function App() {  
  const urlParams = new URLSearchParams(window.location.search);
  var cookie = new Cookies();
  let maintenanceOverrideKey = urlParams.get('maintenanceOverrideKey') ?? cookie.get('maintenanceOverrideKey');
  if (process.env.REACT_APP_MAINTENANCE_MODE === 'ON') {
    if (maintenanceOverrideKey === "CLEAR") {
      cookie.remove('maintenanceOverrideKey');
    } else if (maintenanceOverrideKey === process.env.REACT_APP_MAINTENANCE_OVERRIDE_KEY){
      cookie.set('maintenanceOverrideKey', maintenanceOverrideKey, { path: '/' });
    }
  } else {
    cookie.remove('maintenanceOverrideKey');
  }

  const maintenanceMode = process.env.REACT_APP_MAINTENANCE_MODE === 'ON' && !(maintenanceOverrideKey === process.env.REACT_APP_MAINTENANCE_OVERRIDE_KEY);


  // initialize event listeners
  useWindowEventListener(eventsToListen.CREATE_ORDER_SUCCESS, (data) => {
    store.dispatch(createOrderSuccess((data as CustomEvent)["detail"]));
  });
  useWindowEventListener(eventsToListen.UPDATE_ORDER_SUCCESS, (data) => {
    store.dispatch(updateOrderSuccess((data as CustomEvent)["detail"]));
  });
  GAinit();
  LicenseInfo.setLicenseKey('3877f4f3045fc69e3d8afff03c57bf1dT1JERVI6NDM0MDksRVhQSVJZPTE2ODM3ODI3MDIwMDAsS0VZVkVSU0lPTj0x');


  return (
    <ThemeProvider theme={Theme}>
      <StylesProvider generateClassName={generateClassName}>
        <ApolloProvider client={client}>
          <Provider store={store}>
            <div className="App" id="body-container">
              <BrowserRouter>
                <EventHandler />
                <AlertProvider>
                  {maintenanceMode ? <MaintenancePage /> : (
                    <>
                      <Switch>
                        <Route exact path="/health"> <h3>Hey There!!! The App is Healthy</h3> </Route>
                        <Route exact path={`/login`} render={() => <LandingApp client={client} />} />
                        <Route exact path={`/logout`} render={() => <LandingApp client={client} />} />
                        <Route exact path={`/terms`} component={TermPage} />
                        <Route exact path={`/maintenance`} component={MaintenancePage} />
                        <Route exact path={`/not-found`} component={ErrorPage} />
                        <Route exact path={`/error`} component={ErrorPage} />
                        <Route exact path={`/unauthorised`} component={UnauthorisedAccess}/>
                        <Route exact path={`/unsubscribe/:guid`} component={Unsubscribe}/>
                        <Route exact path={`*`} component={PortalRoutes} />
                      </Switch>
                      {maintenanceOverrideKey === process.env.REACT_APP_MAINTENANCE_OVERRIDE_KEY ? (
                        <div className='maintenance-override-message'><Box ><i className="ri-settings-5-fill gear-icon" /><Box pl={1}>Maintenance</Box></Box></div>
                      ): ''}
                    </>
                  )}
                </AlertProvider>
              </BrowserRouter>
              {process.env.REACT_APP_ENV_VERSION_NOTE ? (
                <div className='version-notification-message'>{process.env.REACT_APP_ENV_VERSION_NOTE ?? ''}</div>
              ): ''}
              {process.env.REACT_APP_ENV_VERSION_NOTE_HIDDEN ? (
                <div style={{ display: 'none' }} id="version-hidden-no-dashboard">{process.env.REACT_APP_ENV_VERSION_NOTE_HIDDEN ?? ''}</div>
              ): ''}
            </div>
          </Provider>
        </ApolloProvider>
      </StylesProvider>
    </ThemeProvider>
  );
}


export const PortalRoutes = () => {
  const location = useLocation();
  const isCarrierCustomerMapped = useSelector(getIsCarrierMapped);
  const defaultCustomer = useSelector(getCustomerDetails);
  const [loginSuccess] = useState<string | null>(new URLSearchParams(location.search).get('loginsuccess'));
  const [getSsoLoginDetails, { data : userDetails}] = useLazyQuery<GetUserDetailsResponse>(GET_SSO_USERDETAILS, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only'
  });
  const [authenticated, setAuthenticated] = useState(false);
  const [mappingUpdated, setMappingUpdated] = useState(false);
  const userDetailFromStore = useSelector(selectUserDetails);
  const isconsentrequired = (userDetailFromStore?.user?.isconsentrequired ?? 'Y');
  const history = useHistory();
  const [defaultAccountSelected, setDefaultAccountSelected] = useState<boolean>(false);

  
  const [ getSequenceIdMap ] = useLazyQuery<GetFiedsByScreenIDResponse>(GET_ALL_FIELD_MASTER);

  useEffect(() => {   
    if (!authenticated) {
      getSsoLoginDetails();
    }
  },[authenticated, getSsoLoginDetails]);

  useEffect(() => {   
    if (authenticated && !mappingUpdated) {
      getSequenceIdMap().then(({ data }) => {
        setMappingUpdated(true);
        if (data?.getAllFieldDetails && data?.getAllFieldDetails?.length > 0) {
          store.dispatch(updateSeqFieldMapping(data?.getAllFieldDetails));
          setPrefFields(data?.getAllFieldDetails);
        }
      });
    }
  },[authenticated, getSequenceIdMap, mappingUpdated]);

  useEffect(() => {
    if (userDetails) {
      if (userDetails?.getUserdetails === null) {
        const prevPath = window.location.pathname ?? '';
        if (prevPath !== '/' && !prevPath.includes('not-found') && !prevPath.includes('switch-service') && !prevPath.includes('switch-account') && !prevPath.includes('/error') && !prevPath.includes('/unauthorised') ) {
          localStorage.setItem("redirect-path", window.location.pathname+window.location.search);
        }
        history.push("/login");
      }
      else {
        store.dispatch(userUpdateDetails((userDetails?.getUserdetails)));
        store.dispatch(resetSessionTimer(new Date().getTime()));
        if (userDetails.getUserdetails) {
          setUserDetails(userDetails.getUserdetails);
        }
        let redirectUrl = localStorage.getItem("redirect-path") ?? '';
        let activeAccountSelected = false;
        let onlyInternationalAvailable = false;
        

        if (userDetails?.getUserdetails?.userdetails?.roletype?.toLowerCase() === RoleType.CUSTOMER.toLowerCase()) {
          if ((userDetails?.getUserdetails?.customerReferenceLists?.length ?? 0) > 0 && !userDetails?.getUserdetails?.customerReferenceLists?.find(item => item.servicetype === ServiceTypes.DOMESTIC)) {
            onlyInternationalAvailable = true;
            redirectUrl = process.env.REACT_APP_INTERNATIONAL_LOGIN_LINK ?? '';
          } else {
            activeAccountSelected = !!userDetails?.getUserdetails?.customerReferenceLists?.find(item => item.active);
            if (!activeAccountSelected) {
              redirectUrl = '/switch-account';
            }
          }
        } else if (userDetails?.getUserdetails?.userdetails?.roletype?.toLowerCase() === RoleType.INTERNAL.toLowerCase()) {
          if (!userDetails?.getUserdetails?.internalUserAccessReference?.find(item => item.servicetype === ServiceTypes.DOMESTIC)) {
            onlyInternationalAvailable = true;
            redirectUrl = process.env.REACT_APP_INTERNATIONAL_INTERNAL_LOGIN_LINK ?? '';
          } else {
            activeAccountSelected = !!userDetails?.getUserdetails?.internalUserAccessReference?.find(item => item.active);
            if (!activeAccountSelected) {
              redirectUrl = '/switch-account';
            }
          }
        } else {
          activeAccountSelected = true;
        }

        if (onlyInternationalAvailable) {
          window.location.href = redirectUrl;
        } else {
          trackLoginAnalytics(userDetails?.getUserdetails?.userdetails?.userid); 
          var cookie = new Cookies();
          cookie.set('RoleType', userDetails?.getUserdetails?.userdetails?.roletype, { path: '/' }) 
          setAuthenticated(true);
          setDefaultAccountSelected(activeAccountSelected);
          if (activeAccountSelected) {
            if (redirectUrl && !redirectUrl.includes('not-found') && !redirectUrl.includes('switch-service') 
                && !redirectUrl.includes('switch-account') && !redirectUrl.includes('/error') 
                && !redirectUrl.includes('/unauthorised') && !redirectUrl.includes('unsubscribe')) {
              history.push(redirectUrl);
              localStorage.removeItem("redirect-path");
            }
            else if (loginSuccess === '1' && window.location.pathname === '/dashboard') {
              history.push(GetDefaultUrl(userDetails?.getUserdetails?.userSettingsList.find(item => item.preference_type_no === PreferenceTypes.HOME_PAGE)?.field_value));
            }
          } else {
            // history.push(redirectUrl);
          }
        }
      
      }
    }
  }, [history, userDetails, loginSuccess]);

  useEffect(() => {
    var cookie = new Cookies();
    if (userDetails?.getUserdetails?.userdetails?.roletype?.toLowerCase() === RoleType.CUSTOMER.toLowerCase()) {
      cookie.set('isBigW', defaultCustomer?.bigW ? "Y" : "N", { path: '/' }) 
    } else {
      cookie.remove('isBigW')
    }
  }, [defaultCustomer?.bigW, userDetails?.getUserdetails?.userdetails?.roletype])

  const displayHeaderBanner = window.location.pathname !== '/switch-account';

  const redirectAccountManagementUrl = useMemo(() => getAccountManagementRedirect(userDetailFromStore), [userDetailFromStore]);

  return (
    <>
      {authenticated && <SessionAlert /> }
      {authenticated && mappingUpdated && (defaultAccountSelected || !isCarrierCustomerMapped) && isconsentrequired === 'N'  &&
        <div>
          {displayHeaderBanner ? <Banner /> : ''}
          {!isCarrierCustomerMapped ? <CarrierCustomerNotMapped /> : '' }
          <div>
          {displayHeaderBanner ? <Navigation />  : ''}
          </div>
          <div className={displayHeaderBanner ? 'app-container' : ''} id="app-container">
            {displayHeaderBanner ? <Header /> : ''}
            <React.Suspense fallback="Loading...">
              <React.Fragment>
                <Switch>
                  <Route exact path={`/`} render={() => <DashboardComponent />} />
                  <Route exact path={`/dashboard`} render={() => <DashboardComponent />} />
                  <Route path={`/account_mngt_redirect`}> <Redirect to={redirectAccountManagementUrl}/></Route>
                  {/* Order micro app  routes */}
                  <Route path={`/order*`} render={() => <FetchOrderApp client={client} />} />
                  <Route path={`/booking*`} render={() => <FetchOrderApp client={client} />} />
                  <Route path={`/load`} render={() => <CreateLoadApp client={client} />} />
                  {/* Admin micro app  routes */}
                  <Route exact path={`/admin/watchlist`} render={() => <CreateDcAdminApp client={client} />} />
                  <Route exact path={`/admin/gatehouse*`} render={() => <CreateDcAdminApp client={client} />} /> 
                  <Route path={`/admin*`} render={() => <FetchAdminApp client={client} />} />
                  <Route path={`/epod`} render={() => <CreateEPodApp client={client} />} />
                  <Route path={`/gatehouse*`} render={() => <FetchGatehouseApp client={client} />} />
                  <Route path={`/report/compliance`} render={() => <CreateEPodApp client={client} />} />
                  <Route path={`/dc-admin`} render={() => <CreateDcAdminApp client={client} />} />
                  <Route exact path={`/preplanning`} component={PrePlanning} />
                  <Route exact path={`/settings`} component={TabMenu} />
                  <Route exact path={`/UnauthorisedAccess`}> <Redirect to='/unauthorised'/> </Route>
                  <Route path={`/error-page`} component={ErrorUrl}/>
                  <Route path={`/feedback`} component={Feedback}/>
                  <Route exact path={`/switch-service`}> <Redirect to='/dashboard'/> </Route>
                  <Route path={`/switch-account`} component={SwitchAccountComponent} />
                  <Route path={`*`} component={ErrorPageRedirect}/>
                </Switch>
                <div id='micro-apps' />
              </React.Fragment>
            </React.Suspense>
            <Footer />
          </div>
        </div>
        }
        {authenticated && !defaultAccountSelected && isCarrierCustomerMapped && isconsentrequired === 'N'  &&
          <>
            {userDetails?.getUserdetails?.userdetails?.roletype?.toLowerCase() === RoleType.INTERNAL.toLowerCase()
              ? <SwitchServiceTypeInterface />
              : <SwitchAccount />}
          </>
        }
        <input type="hidden" name="userId" value={userDetailFromStore?.user?.userid ?? ''} />
        {authenticated && isconsentrequired !== 'N' && 
            <React.Fragment>
              <TermsModal view="modal" />
            </React.Fragment>
        }
        {!authenticated && <Box display="flex" height="100vh" width="100hw" alignItems="center" justifyContent="center"><CircularProgress size={60} /></Box> }
    </>
  );
}

const SwitchAccountComponent = () => {
  const userDetails = useSelector(selectUserDetails);
  return (
    <>
      {userDetails?.user?.roletype?.toLowerCase() === RoleType.INTERNAL.toLowerCase()
        ? <SwitchServiceTypeInterface />
        : <SwitchAccount displayBack/>}
    </>
  )
}

const DashboardComponent = () => {
  const userObj = useSelector(selectUserDetails);
  const userRoleType = userObj.user?.roletype;

  return (
    <>
      {userRoleType === RoleType.CUSTOMER ? <Dashboard /> : <Dashboard /> }
    </>
  )
}


export default App;
