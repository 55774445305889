
import React, { useState, useCallback, useEffect, useRef, useMemo } from 'react';
// import { styled } from '@mui/material/styles';
import './Header.scss';
import {
    Box,
    Grid,
    Typography,
    Avatar,
    Menu,
    MenuItem,
    IconButton,
    Tooltip,
    withStyles,
    Drawer,
    List,
    ListItem,
    Popper,
    ClickAwayListener,
    Accordion,
    AccordionSummary,
    AccordionDetails
} from  '@material-ui/core';
import { useLazyQuery, useMutation  } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserDetails, getCompanyName, getIsImpersonated } from '../../store/user/user.selectors';
import { useHistory, Link} from "react-router-dom";
import { CLEAR_USER_SESSION, GET_SSO_USERDETAILS } from '../../graphql/Queries/UserDetailsLogin';
import { SectionPermission, Sections } from '../utils/permission';
import ImpersonationModal from '../Impersonation/ImpersonationModal';
import { GET_TRANSPORT_ORDER_BY_SEARCH_KEYWORD } from '../../graphql/Queries/getTransportOrderBySearchKeyword';
import { SearchBox } from 'csp-common-ui';
import { Tag } from 'csp-common-ui';
import { UPDATE_USER_SETTINGS } from '../../graphql/Queries/updateUserSettings';
import { UpdateUserSettingsRequest, UpdateUserSettingsResponse } from '../../models/api/update-user-setings';
import { PreferenceTypes } from '../../models/preference-type-mapping';
import { GetUserDetailsResponse } from '../../models/api/get-sso-userdetails';
import { GET_TRANSPORT_ORDER_STATUS_BY_IDS } from '../../graphql/Queries/getTransportOrderStatusByIds';
import { userUpdateDetails } from '../../store/user/user.actions';
import dayjs from 'dayjs';
import { GetTransportOrderBySearchKeywordRequest, GetTransportOrderBySearchKeywordResponse } from '../../models/api/get-transport-order-by-search-keyword';
import GlobalSearchGroupAccordion, { ResultData }  from './GlobalSearchResultItem';
import { uniq } from 'lodash';
import { GetTransportOrderStatusByIdsResponse, GetTransportOrderStatusByIdsRequest } from '../../models/api/get-transport-order-status-by-ids';
import HelpDrawer from './helpDrawer';
import { RoleType } from '../../models/user-roles';
import { GetTransportLoadStatusByIdsRequest, GetTransportLoadStatusByIdsResponse } from '../../models/api/get-transport-load-status-by-ids';
import { GET_TRANSPORT_LOAD_STATUS_BY_IDS } from '../../graphql/Queries/getTransportLoadStatusByIds';
import { parseSearchTag } from '../utils/global-search';
import { CARRIER_FV } from '../utils/carrierSubRole';
import { clearUserSessionStorage } from '../utils/helper';


export const handleHeightOfTable = (length: number) =>{
    let bannerContainer = document.getElementById('banner-container');
    if(bannerContainer){
        let bannerHeight = bannerContainer.getBoundingClientRect().bottom;
        if(length < 50){
            return  bannerHeight ? `calc(100vh - ${bannerHeight}px - 150px)` : 'calc(100vh - 150px)'  
        }
        else{
            return  bannerHeight ?  `calc(100vh - ${bannerHeight}px - 230px)` : 'calc(100vh - 230px)' 
        }
    }
}


interface RecentHistory {
    transportOrderId: string,
    matchId: string,
    type: "CR" | "TB" | "LDID" | "SHID" | "PO",
    orderStatus: string
}



const Header = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const minSearchQueryLength = 2;
    const userDetails = useSelector(selectUserDetails);
    const userRoleType = userDetails.user?.roletype;
    const isFruitAndVegCarrier = useMemo(() => !!(userDetails?.user?.userrolexrefList?.find((item) => item.accessroleid === CARRIER_FV)), [userDetails?.user?.userrolexrefList]);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [anchorMenu, setAnchorMenu] = useState<boolean>(false);
    const [state, setState] = useState({ bottom: false, right: false });
    const [impersonateModal, setImpersonateModal] = useState<boolean>(false);
    const isImpersonated = useSelector(getIsImpersonated);
    const searchCloseButtonRef = useRef<HTMLButtonElement>();
    const userFirstName = (userDetails?.user?.firstname ?? '').charAt(0).toUpperCase();
    const userLastName = (userDetails?.user?.lastname ?? '').charAt(0).toUpperCase();
    const [keyword, setKeyword] = React.useState<string>('');
    const [onOpen, setOnOpen] = React.useState(false);
    let globalSearchOption = sessionStorage.getItem('search-option') ?? 'all';
    if (userRoleType === RoleType.CARRIER) {
        globalSearchOption = isFruitAndVegCarrier ? "all" : "LDID";
    } else if (userRoleType === RoleType.CUSTOMER){
        globalSearchOption = sessionStorage.getItem('search-option') !== "LDID" ? sessionStorage.getItem('search-option') ?? 'all' : 'all'
    }

    const [anchorGlobalSearch, setAnchorGlobalSearch] = useState<any>(null);
    const [focus, setFocus] = useState<boolean>(false);
    const [selectedGlobalSearchOption, setSelectedGlobalSearchOption] = useState<string>(globalSearchOption);
    const [globalSearchResultCount, setGlobalSearchResultCount] = useState<number>(0);
    const [selectedOptionResultCount, setSelectedOptionResultCount] = useState<number>(0);
    const [parseSearchResults, setParsedSearchResult] = useState<{[key: string]: ResultData[]}>({});
    const [recentHistoryParsed, setRecentHistoryParsed] = useState<RecentHistory[]>([]);

    
    const globalSearchOptions = userRoleType === RoleType.CARRIER ? undefined : [
        { label: "All", value: "all", short: "All"},
        { label: "Purchase order number", value: "PO", short: "PO"},
        { label: "Transport Booking", value: "TB", short: "TB"},
        { label: "Shipment ID", value: "SHID", short: "SHID"},
        { label: "Customer reference number", value: "CR", short: "CR"},
        ...(userRoleType === RoleType.INTERNAL ? [{ label: "Load ID", value: "LDID", short: "LDID"}] : []),
    ];

    const [parseSearchTotalResults, setParseSearchTotalResults] = useState({tbListTotal: 0, crListTotal: 0, ldidListTotal:0,shidListTotal:0, poListTotal: 0});
    const [getTransportOrders, { data: orderData, error: apolloerror, loading: loadingGlobalSearch }] = useLazyQuery<GetTransportOrderBySearchKeywordResponse, GetTransportOrderBySearchKeywordRequest>(GET_TRANSPORT_ORDER_BY_SEARCH_KEYWORD, {
        fetchPolicy: "no-cache",
    });
    const [loadRecentHistory, { data: recentHistory }] = useLazyQuery<GetTransportOrderStatusByIdsResponse, GetTransportOrderStatusByIdsRequest>(GET_TRANSPORT_ORDER_STATUS_BY_IDS, {
        fetchPolicy: "cache-and-network",
        nextFetchPolicy: "cache-and-network"
    })
    const [loadRecentLoadHistory, { data: recentLoadHistory }] = useLazyQuery<GetTransportLoadStatusByIdsResponse, GetTransportLoadStatusByIdsRequest>(GET_TRANSPORT_LOAD_STATUS_BY_IDS, {
        fetchPolicy: "cache-and-network",
        nextFetchPolicy: "cache-and-network"
    });
    const [onUpdateUserSettings] = useMutation<UpdateUserSettingsResponse, UpdateUserSettingsRequest>(UPDATE_USER_SETTINGS);
    const [getSsoLoginDetails, { data: updatedUserDetails }] = useLazyQuery<GetUserDetailsResponse>(GET_SSO_USERDETAILS, {
        fetchPolicy: "network-only",
        nextFetchPolicy: "network-only"
    });

    const openImpersonateModal = () => {
        setImpersonateModal(true);
        setAnchorEl(null);
    }

    const onSwitchAccountPage = () => {
        setAnchorEl(null);
        setAnchorMenu(false);
        history.push('/switch-account');
    }

    useEffect(() => {
        if (updatedUserDetails?.getUserdetails) {
            dispatch(userUpdateDetails((updatedUserDetails.getUserdetails)));
        }
    }, [dispatch, updatedUserDetails]);

    useEffect(() => {
        if (keyword?.trim()?.length >= minSearchQueryLength) {
            getTransportOrders({
                variables: {
                    searchKeywords: keyword,
                    searchField: selectedGlobalSearchOption === 'all' ? (
                        userRoleType === RoleType.CUSTOMER ? ["CR", "TB", "SHID", "PO"] : userRoleType === RoleType.CARRIER ? ["LDID", "PO"] : ["CR", "TB", "LDID", "SHID", "PO"]
                    ) : [selectedGlobalSearchOption],
                    pageSize: selectedGlobalSearchOption === 'all' ? 10 : 100,
                    currentPageNo: 0
                }
            });
        }
    }, [getTransportOrders, keyword, selectedGlobalSearchOption, userRoleType]);

    useEffect(() => {
       
        if (orderData?.getTransportOrderBySearchKeyword) {
            setGlobalSearchResultCount(
                orderData?.getTransportOrderBySearchKeyword?.tbList?.totalordersno +
                orderData?.getTransportOrderBySearchKeyword?.crList?.totalordersno +
                orderData?.getTransportOrderBySearchKeyword?.ldidList?.totalLoads +
                orderData?.getTransportOrderBySearchKeyword?.shidList?.totalordersno + 
                orderData?.getTransportOrderBySearchKeyword?.poList?.totalordersno
            );
            setParseSearchTotalResults ({
                tbListTotal: orderData?.getTransportOrderBySearchKeyword?.tbList?.totalordersno || 0,
                crListTotal: orderData?.getTransportOrderBySearchKeyword?.crList?.totalordersno || 0,
                ldidListTotal: orderData?.getTransportOrderBySearchKeyword?.ldidList?.totalLoads || 0,
                shidListTotal: orderData?.getTransportOrderBySearchKeyword?.shidList?.totalordersno || 0,
                poListTotal: orderData?.getTransportOrderBySearchKeyword?.poList?.totalordersno || 0,

            })
            setParsedSearchResult({
                tbList: orderData?.getTransportOrderBySearchKeyword?.tbList?.orderlist?.map(item => ({
                    transportOrderId: item.transportOrderId,
                    matchId: item.transportOrderId,
                    orderStatus: item.orderStatus,
                })),
                crList: orderData?.getTransportOrderBySearchKeyword?.crList?.orderlist?.map(item => ({
                    transportOrderId: item.transportOrderId,
                    matchId: item.customerReference,
                    orderStatus: item.orderStatus,
                })),
                ldidList: orderData?.getTransportOrderBySearchKeyword?.ldidList?.loadList?.map(item => ({
                    transportOrderId: item.loadId,
                    matchId: item.loadId,
                    orderStatus: item.loadStatus,
                })),
                shidList: orderData?.getTransportOrderBySearchKeyword?.shidList?.orderlist?.map(item => ({
                    transportOrderId: item.transportOrderId,
                    matchId: item.shipmentNumber,
                    orderStatus: item.orderStatus
                })),
                poList: orderData?.getTransportOrderBySearchKeyword?.poList?.orderlist?.map(item => ({
                    transportOrderId: item.transportOrderId,
                    matchId: item.poNumber,
                    orderStatus: item.orderStatus
                }))
            });
        }

    }, [orderData]);

    useEffect(() => {
        if (selectedGlobalSearchOption !== 'all' && orderData?.getTransportOrderBySearchKeyword) {
            if (selectedGlobalSearchOption === "TB") {
                setSelectedOptionResultCount(orderData?.getTransportOrderBySearchKeyword.tbList?.totalordersno);
            } else if (selectedGlobalSearchOption === "CR") {
                setSelectedOptionResultCount(orderData?.getTransportOrderBySearchKeyword?.crList?.totalordersno);
            } else if (selectedGlobalSearchOption === "LDID") {
                setSelectedOptionResultCount(orderData?.getTransportOrderBySearchKeyword?.ldidList?.totalLoads);
            } else if (selectedGlobalSearchOption === "SHID") {
                setSelectedOptionResultCount(orderData?.getTransportOrderBySearchKeyword?.shidList?.totalordersno);
            } else if (selectedGlobalSearchOption === "PO") {
                setSelectedOptionResultCount(orderData?.getTransportOrderBySearchKeyword?.poList?.totalordersno);
            }
        }
    }, [orderData?.getTransportOrderBySearchKeyword, selectedGlobalSearchOption]);


    const searchTags = useMemo(() => (userDetails?.userSettingsList ?? [])
        .filter(item => item.preference_type_no === PreferenceTypes.SEARCH_TAG)
        .sort((a, b) => (dayjs(b.updatedatetime).unix() - dayjs(a.updatedatetime).unix()))
    , [userDetails?.userSettingsList]);


    const getHistory = useCallback(() => {
        const sortedHistory = searchTags.map(item => parseSearchTag(item?.field_value ?? ''));
            
        const orderHistory = uniq(sortedHistory
            ?.filter(item => item.type !== "LDID")
            ?.map(item => item.transportOrderId)
        );

        const loadHistory = uniq(sortedHistory
            ?.filter(item => item.type === "LDID")
            ?.map(item => item.transportOrderId)
        );
        if (orderHistory?.length > 0 && focus) {
            loadRecentHistory({
                variables: {
                    transportOrderIds: orderHistory
                }
            });
        }
        if (loadHistory?.length > 0 && focus) {
            loadRecentLoadHistory({
                variables: {
                    loadIds: loadHistory
                }
            });
        }
        
    }, [focus, loadRecentHistory, loadRecentLoadHistory, searchTags]);

    useEffect(() => {
        if (recentHistory?.getTransportOrderStatusByIds || recentLoadHistory?.getLoadStatusByIds) {
            const orderHash: { [key: string]: string } = (recentHistory?.getTransportOrderStatusByIds ?? [])
                ?.reduce((acc, cur) => ({ ...acc, [cur?.id]: cur?.status }), {});

            const loadHash: { [key: string]: string } = (recentLoadHistory?.getLoadStatusByIds ?? [])
                ?.reduce((acc, cur) => ({ ...acc, [cur?.id]: cur?.status }), {});

            const sortedHistory = uniq(searchTags?.map(item => item?.field_value));
            setRecentHistoryParsed(sortedHistory.map(tag => {
                const result = parseSearchTag(tag as string);
                if (result.type ==="LDID" && loadHash[result.transportOrderId] ) {
                    return {
                        ...result,
                        orderStatus: loadHash[result.transportOrderId]
                    }
                } else if (result.type !=="LDID" && orderHash[result.transportOrderId]){
                    return {
                        ...result,
                        orderStatus: orderHash[result.transportOrderId]
                    }
                }
                return null;
            }).filter(item => item !== null) as RecentHistory[]);
        }
    }, [recentHistory, recentLoadHistory?.getLoadStatusByIds, searchTags])

    

    const handleHistory = (value: string) => {
        searchCloseButtonRef.current?.click();
        setFocus(false);
        setKeyword('');
        onUpdateUserSettings({
            variables: {
                request: [{
                    field_value: value,
                    preference_type_no: PreferenceTypes?.SEARCH_TAG,
                }]
            }
        }).then((result) => {
            getSsoLoginDetails();
        })
    }

    useEffect(() => {
        getHistory();
    }, [getHistory]);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const openUserPage = () => {
        setAnchorEl(null);
        setAnchorMenu(false);
        history.push("/settings");
    };

    const openManageUsers = () => {
        window.location.href = (userDetails?.user?.roletype === 'Internal' ? process.env.REACT_APP_INTERNAL_MANAGE_USERS_LINK : process.env.REACT_APP_EXTERNAL_MANAGE_USERS_LINK) ?? '';
    };

    const [clearUserSession, { data: logoutSuccess }] = useLazyQuery(CLEAR_USER_SESSION, {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only'
    });

    const logoutAction = () => {
        clearUserSession();
    }

    useEffect(() => {
        if (logoutSuccess) {
            if (isImpersonated) {
                window.location.href = '/dashboard';
            } else {
                clearUserSessionStorage();
                if (userDetails?.user?.roletype === 'Internal') {
                    window.location.href = process.env.REACT_APP_LOGOUT_SSO_URL ?? '';
                }
                else {
                    window.location.href = process.env.REACT_APP_EXTERNAL_LOGOUT_SSO_URL ?? '';
                }
            }
        }
    }, [logoutSuccess, history, userDetails?.user?.roletype, isImpersonated]);

    const toggleDrawer = (anchor: string, open: boolean) => (event: any) => {
        if (
            event.type === "keydown" && ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }
        setState({ ...state, [anchor]: open });
    };

    const list = (anchor: string) => (
        <Box>
            {anchor === "right" ?
                <HelpDrawer onClose={() => setState({ ...state, [anchor]: false })} />:
                <Box >
                    <Menu open={true} onClose={toggleDrawer(anchor, false)} classes={{ paper: 'profile-menu-bottom' }}>
                        <MenuItem className="mobile-notification" onClick={toggleDrawer("right", true)}>
                            <IconButton size="medium">
                                <i className="ri-question-fill icon" style={{ color: 'secondary', backgroundColor: "secondary" }} />
                                <Typography variant="subtitle1" className="button">Help</Typography>
                            </IconButton>
                        </MenuItem>
                        <MenuItem className="mobile-notification" onClick={toggleDrawer(anchor, false)}>
                            <IconButton size="medium">
                                <i className="ri-close-fill icon"></i>
                                <Typography variant="subtitle1" className="button">Close</Typography>
                            </IconButton>
                        </MenuItem>
                    </Menu>
                </Box>
            }
         </Box>  
             
    );
    

    const DarkTooltip = withStyles((theme) => ({
        tooltip: {
            backgroundColor: theme.palette.secondary.main,
            boxShadow: '0px 0px 0px rgba(0, 0, 0, 0.1), 0px 0px 1px rgba(0, 0, 0, 0.08)'
        },
    }))(Tooltip);

    let flag: boolean = false;
    if (apolloerror?.message.includes("code 400") || apolloerror?.message.includes("code 500") || apolloerror?.message.includes("code 404") || apolloerror?.message.includes("code 503")) {
        flag = true
    }

    const handleSwitchSelectedSearchOption = (option: string) => {
        if (selectedGlobalSearchOption !== option) {
            setParsedSearchResult({});
            setGlobalSearchResultCount(0);
            setSelectedOptionResultCount(0);
            setSelectedGlobalSearchOption(option);
            sessionStorage.setItem('search-option', option)
        }
    }

    return (
        <Box pl={4} pr={4} className="header-profile-section" data-testid='mpc-app-header' id="header-profile-section">
            <Grid container>
                <Grid item className="header-profile-first-row" xs={12} >
                    <Box display="flex" flexDirection="column" className="global-search" id='global-search-container'>
                        <SectionPermission permissions={[Sections.GLOBAL_SEARCH]}>
                            <SearchBox
                                id='global-search-input-element'
                                closeButtonRef={searchCloseButtonRef}
                                placeholder={`Search (minimum ${minSearchQueryLength } characters)`}
                                onChange={(keyword, event, parentRef) => {
                                    setKeyword(keyword);
                                    setAnchorGlobalSearch(parentRef);
                                    setFocus(true);
                                }}
                                onClose={() => {
                                    setFocus(false)
                                    setKeyword('');
                                    handleSwitchSelectedSearchOption(globalSearchOption)
                                }}
                                errorFlag={flag}
                                onFocus={(event, parentRef) => {
                                    setFocus(true);
                                    setAnchorGlobalSearch(parentRef);
                                }}
                                width={525}
                                isGlobalSearch={true}
                                searchOptions={globalSearchOptions}
                                onOptionChange={(option) => {
                                    handleSwitchSelectedSearchOption(option);
                                    setFocus(true);
                                }}
                                defaultSearchOption={selectedGlobalSearchOption}
                                displayShadow={true}
                            />
                        </SectionPermission>
                        { focus && (keyword.length > 0 || recentHistory?.getTransportOrderStatusByIds.length !== 0) && (
                            <ClickAwayListener onClickAway={(e) => { 
                                setFocus(!!(
                                    document.getElementById("global-search-input-element")?.contains(e.target  as HTMLElement)
                                    || document.getElementById("global-search-input-element-mob")?.contains(e.target  as HTMLElement)
                                    || !document.contains(e.target  as HTMLElement)
                                ));
                            }}>
                                <Popper
                                    data-testid="search-history"
                                    open={focus && (keyword.length >= minSearchQueryLength || recentHistory?.getTransportOrderStatusByIds.length !== 0)}
                                    anchorEl={anchorGlobalSearch?.current}
                                    className='global-search-popup'
                                    modifiers={{
                                        flip: {
                                            enabled: false,
                                        },
                                        offset: {
                                            enabled: true,
                                            offset: '0, 4',
                                        },
                                        preventOverflow: {
                                            escapeWithReference : true,
                                        }
                                    }}
                                    popperOptions={{
                                        placement:'bottom-end',
                                    }}
                                        
                                >
                                    { keyword.length >= minSearchQueryLength ? (
                                        <>
                                            {flag ? (
                                                <Box className="error-alert no-orders-found" display="flex" data-testid="error-alert">
                                                    <i className="ri-error-warning-fill error-alert-icon"></i>
                                                    <Typography variant="body1"> Sorry, there was a problem retrieving results. Please try again later.</Typography>
                                                </Box>
                                            ) : (
                                                loadingGlobalSearch ? (
                                                    <Box justify-content="center" className="no-orders-found" data-testid="no-results-found">
                                                        <Typography variant="body1">Loading...</Typography>
                                                    </Box>
                                                ) : (
                                                    <>
                                                        { globalSearchResultCount > 0 ? (
                                                            <>
                                                                <Box className="global-search-table" data-testid="global-search-table" maxHeight={handleHeightOfTable(globalSearchResultCount)}>
                                                                    <GlobalSearchGroupAccordion
                                                                        title="Purchase order number"
                                                                        type="PO"
                                                                        total = {parseSearchTotalResults.poListTotal}
                                                                        data={parseSearchResults.poList}
                                                                        handleHistory={handleHistory}
                                                                        selected={selectedGlobalSearchOption}
                                                                        onSelectAll={() => handleSwitchSelectedSearchOption("PO")}
                                                                        keyword={keyword}
                                                                    />
                                                                    <GlobalSearchGroupAccordion 
                                                                        title="Transport booking"
                                                                        type="TB"
                                                                        total = {parseSearchTotalResults.tbListTotal}
                                                                        data={parseSearchResults.tbList}
                                                                        handleHistory={handleHistory}
                                                                        selected={selectedGlobalSearchOption}
                                                                        onSelectAll={() => handleSwitchSelectedSearchOption("TB")}
                                                                        keyword={keyword}
                                                                    />
                                                                    <GlobalSearchGroupAccordion
                                                                        title="Shipment ID"
                                                                        type="SHID"
                                                                        total = {parseSearchTotalResults.shidListTotal}
                                                                        data={parseSearchResults.shidList}
                                                                        handleHistory={handleHistory}
                                                                        selected={selectedGlobalSearchOption}
                                                                        onSelectAll={() => handleSwitchSelectedSearchOption("SHID")}
                                                                        keyword={keyword}
                                                                    />
                                                                    <GlobalSearchGroupAccordion
                                                                        title="Customer reference"
                                                                        type="CR"
                                                                        total = {parseSearchTotalResults.crListTotal}
                                                                        data={parseSearchResults.crList}
                                                                        handleHistory={handleHistory}
                                                                        selected={selectedGlobalSearchOption}
                                                                        onSelectAll={() => handleSwitchSelectedSearchOption("CR")}
                                                                        keyword={keyword}
                                                                    />
                                                                    <GlobalSearchGroupAccordion
                                                                        title="Load ID"
                                                                        type="LDID"
                                                                        total = {parseSearchTotalResults.ldidListTotal}
                                                                        data={parseSearchResults.ldidList}
                                                                        handleHistory={handleHistory}
                                                                        selected={selectedGlobalSearchOption}
                                                                        onSelectAll={() => handleSwitchSelectedSearchOption("LDID")}
                                                                        keyword={keyword}
                                                                    />
                                                                </Box>
                                                                {selectedGlobalSearchOption === 'all' && globalSearchResultCount > 25 ? (
                                                                    globalSearchResultCount >= 50
                                                                        ? ( <Box justify-content="center" className="more-record-banner" data-testid="no-results-found">
                                                                            <Typography variant="body1">
                                                                                More than <Typography variant="subtitle1" component='span'>{globalSearchResultCount >= 100 ? 100 : 50} </Typography>
                                                                                results found, keep typing to narrow your search, adding an extra character or selecting a specific tracking number.
                                                                            </Typography>
                                                                        </Box> )
                                                                        : ( <Box justify-content="center" className="more-record-banner" data-testid="no-results-found">
                                                                            <Typography variant="body1">
                                                                                More than <Typography variant="subtitle1" component='span'>25</Typography> results found, keep typing to narrow your search.
                                                                            </Typography>
                                                                        </Box> )
                                                                ): selectedOptionResultCount > 25 && (
                                                                    <Box justify-content="center" className="more-record-banner" data-testid="no-results-found">
                                                                        <Typography variant="body1">
                                                                            More than <Typography variant="subtitle1" component='span'>{globalSearchResultCount >= 100 ? 100 : (globalSearchResultCount >= 50 ? 50 : 25)} </Typography> results found, keep typing to narrow your search.
                                                                        </Typography>
                                                                    </Box>
                                                                )}
                                                            </>
                                                        ) : (
                                                            <Box justify-content="center" className="no-orders-found" data-testid="no-results-found">
                                                                <Typography variant="body1">No results found</Typography>
                                                            </Box>
                                                        )}
                                                    </>
                                                )
                                            )}
                                        </>
                                    ) : keyword?.trim()?.length === 0 && (
                                        <Box className="global-search-table" data-testid="global-search-table" maxHeight={handleHeightOfTable(recentHistoryParsed.length)} >
                                            <Accordion defaultExpanded className="search-accordion recent" expanded={true}>
                                                <AccordionSummary
                                                    expandIcon={<i className="ri-arrow-drop-down-line accordion-icon" />}
                                                    className="search-accordion-header"
                                                    disabled
                                                >
                                                    <Typography variant='subtitle1' color="textPrimary">Recent searches</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails className="search-accordion-content">
                                                    <Box>
                                                        {recentHistoryParsed
                                                            ?.filter(
                                                                item => 
                                                                    (userRoleType === RoleType.CARRIER && isFruitAndVegCarrier && (item.type === "LDID" || item.type === "PO"))
                                                                    || (userRoleType === RoleType.CARRIER && !isFruitAndVegCarrier && item.type === "LDID")
                                                                    || (userRoleType === RoleType.CUSTOMER && item.type !== "LDID")
                                                                    || (userRoleType === RoleType.INTERNAL)).map((item, index) => (
                                                            <Link
                                                                key={index}
                                                                to={item.type === "LDID"
                                                                    ? `/load/details/${item?.matchId}`
                                                                    : `/booking/detail/${item?.transportOrderId}`}
                                                                className="search-result-item"
                                                                color="textPrimary"
                                                                onClick={() => handleHistory(item.type === "TB" ? item.transportOrderId : `TB-${item.transportOrderId}-${item.type}-${item.matchId}`)}
                                                            >
                                                                <Box className="search-result-list--item-box" key={`${item.transportOrderId}-${item.matchId}`} display="flex" alignItems="center">
                                                                    <Box width={40} display="flex" alignItems="center">
                                                                        <Typography variant='subtitle1' color="textPrimary">{item.type}#</Typography>
                                                                    </Box>
                                                                    <Box flexGrow="1" pl={3} pr={2} className="search-order-link">
                                                                        <Typography variant='body2' color="textPrimary">{item.matchId}</Typography>
                                                                    </Box>
                                                                    <Box width={150} minWidth={150} display="flex" alignItems="center"><Tag closeAction={false} type={item?.orderStatus?.toLowerCase() as any} size="small" /></Box>
                                                                </Box>
                                                            </Link>
                                                        ))}
                                                    </Box>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Box>
                                    )}
                                </Popper>
                            </ClickAwayListener>
                        )}
                    </Box>

                    <Box className="search-icon-mob">
                        <i className="ri-search-2-line ri-lg" onClick={() => {setOnOpen(true)}} />
                    </Box>
                    <div className="header-profile-first-colm">
                        <span className="header-profile-name">
                            <Typography variant="body1">{userDetails?.user?.firstname} {userDetails?.user?.lastname}</Typography>
                            <Typography variant="body2" color="textSecondary">{useSelector(getCompanyName)}</Typography>
                        </span>
                        <IconButton
                            className="avatar-button user-profile"
                            disableRipple
                            onClick={handleClick}
                            data-testid='profile-button-desktop'
                            >
                            <Avatar className="avatar-icon">{userFirstName}{userLastName}</Avatar>
                        </IconButton>
                        <IconButton
                            className="avatar-button menu-button"
                            data-testid="avatar"
                            disableRipple
                            onClick={() => setAnchorMenu(true)}
                            >
                            <Avatar className="avatar-icon">{userFirstName}{userLastName}</Avatar>
                        </IconButton>
                        <Drawer
                            anchor={'bottom'}
                            open={anchorMenu}
                            onClose={() => setAnchorMenu(false)}
                        >
                            <Box color="secondary.main">
                                <List
                                    component="nav"
                                    aria-labelledby="nested-list-subheader"
                                >
                                    <SectionPermission permissions={[Sections.SWITCH_ACCOUNTS_HEADER_OPTION]}>
                                        {(!isImpersonated)? (
                                            <ListItem button onClick={onSwitchAccountPage} data-testid="impersonation-menu-mobile">
                                                <Box pt={2} pb={1} pr={1} ><i className="ri-account-circle-line ri-xl"></i></Box>
                                                <Typography variant="subtitle1" color="inherit" >Accounts</Typography>
                                            </ListItem>
                                        ) : ''}
                                    </SectionPermission>
                                    <ListItem button onClick={openUserPage}>
                                        <Box pt={2} pb={1} pr={1} ><i className="ri-settings-4-line ri-xl"></i></Box>
                                        <Typography variant="subtitle1" color="inherit" data-testid="setting-menu">Settings</Typography>
                                    </ListItem>
                                    <SectionPermission permissions={[Sections.IMPERSONATE_USERS]}>
                                        {!isImpersonated ? (
                                            <ListItem button onClick={openImpersonateModal} data-testid="impersonation-menu-mobile">
                                                <Box pt={2} pb={1} pr={1} ><i className="ri-account-circle-line ri-xl"></i></Box>
                                                <Typography variant="subtitle1" color="inherit" >Impersonation</Typography>
                                            </ListItem>
                                        ) : ''}
                                    </SectionPermission>
                                    <SectionPermission permissions={[Sections.MY_PROFILE_ADMIN_USERS]}>
                                        <ListItem button onClick={openManageUsers}>
                                            <Box pt={2} pb={1} pr={1} ><i className="ri-account-circle-line ri-xl"></i></Box>
                                            <Typography variant="subtitle1" color="inherit">Manage users</Typography>
                                        </ListItem>
                                    </SectionPermission>
                                    <ListItem button onClick={logoutAction} data-testid='logout-button-mobile'>
                                        <Box pt={2} pb={1} pr={1}  ><i className="ri-logout-box-r-line ri-xl"></i></Box>
                                        <Typography color="inherit" variant="subtitle1">Sign out</Typography>
                                    </ListItem>
                                    <ListItem button onClick={() => setAnchorMenu(false)}>
                                        <Box pt={2} pb={1} pr={1} ><i className="ri-close-line ri-xl"></i></Box>
                                        <Typography variant="subtitle1" color="inherit">Close</Typography>
                                    </ListItem>
                                </List>
                            </Box>
                        </Drawer>
                        <Menu
                            classes={{ paper: 'profile-menu' }}
                            data-testid="menus"
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            PaperProps={{
                                style: {
                                    marginLeft: "50px",
                                    marginTop: "5px"
                                }
                            }}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        getContentAnchorEl={
                            null
                        }
                        >
                            <SectionPermission permissions={[Sections.SWITCH_ACCOUNTS_HEADER_OPTION]}>
                                {(!isImpersonated)? (
                                    <MenuItem onClick={onSwitchAccountPage} data-testid="impersonation-menu-desktop">
                                        <Box pt={1} pr={1} color="action.active"><i className="ri-account-circle-line ri-xl"></i></Box>
                                        <Typography variant="body1" color="textPrimary" >Accounts</Typography>
                                    </MenuItem>
                                ) : ''}
                            </SectionPermission>
                            <MenuItem
                                onClick={openUserPage}>
                                <Box pt={1} pr={1} color="action.active"><i className="ri-settings-4-line ri-xl"></i></Box>
                                <Typography variant="body1" color="textPrimary" data-testid="setting-menu">Settings</Typography>
                            </MenuItem>
                            <SectionPermission permissions={[Sections.IMPERSONATE_USERS]}>
                                {!isImpersonated ? (
                                    <MenuItem onClick={openImpersonateModal} data-testid="impersonation-menu-desktop">
                                        <Box pt={1} pr={1} color="action.active"><i className="ri-account-circle-line ri-xl"></i></Box>
                                        <Typography variant="body1" color="textPrimary" >Impersonation</Typography>
                                    </MenuItem>
                                ) : ''}
                            </SectionPermission>
                            <SectionPermission permissions={[Sections.MY_PROFILE_ADMIN_USERS]}>
                                <MenuItem
                                    onClick={openManageUsers}>
                                    <Box pt={1} pr={1} color="action.active"><i className="ri-account-circle-line ri-xl"></i></Box>
                                    <Typography variant="body1" color="textPrimary">Manage users</Typography>
                                </MenuItem>
                            </SectionPermission>
                            <MenuItem
                                onClick={logoutAction} data-testid="logout-button-desktop">
                                <Box pt={1} pr={1} color="action.active"><i className="ri-logout-box-r-line ri-xl"></i></Box>
                                <Typography variant="body1" color="textPrimary">Sign out</Typography>
                            </MenuItem>
                        </Menu>
                        <DarkTooltip
                            placement='bottom'
                            className='header-profile-icon'
                            title={<Typography variant='caption'>Help</Typography>} 
                            >
                            <IconButton id="help" color="secondary" size='small' onClick={toggleDrawer("right", true)} >
                                <i className="ri-question-fill ri-lg" />
                            </IconButton>
                        </DarkTooltip>
                        {(['right', 'bottom'] as const).map((anchor) => (
                            <React.Fragment key={anchor}>
                                <Drawer
                                    anchor={anchor}
                                    open={state[anchor]}
                                    onClose={toggleDrawer(anchor, false)}
                                >
                                    {list(anchor)}
                                </Drawer>
                            </React.Fragment>
                        ))}
                        <button className="header-short-button" onClick={toggleDrawer("bottom", true)}><i className="ri-more-2-fill ri-xl"></i></button>
                    </div>
                    {impersonateModal && (
                        <ImpersonationModal onClose={() => setImpersonateModal(false)} />
                    )}
                </Grid>
            </Grid>

            {onOpen ?
                <Box className="searchbar-active" id='global-search-container-mob'>
                    <SectionPermission permissions={[Sections.GLOBAL_SEARCH]}>
                        <SearchBox
                            data-testid="search-box"
                            id='global-search-input-element-mob'
                            closeButtonRef={searchCloseButtonRef}
                            placeholder={`Search (minimum ${minSearchQueryLength } characters)`}
                            onChange={(keyword, event, parentRef) => {
                                setKeyword(keyword);
                                setAnchorGlobalSearch(parentRef);
                                setFocus(true);
                            }}
                            defaultOpen={true}
                            fullWidth={true}
                            onClose={() => {
                                setFocus(false)
                                setKeyword('')
                                setOnOpen(false);
                                handleSwitchSelectedSearchOption(globalSearchOption);
                            }}
                            errorFlag={flag}
                            onFocus={(event, parentRef) => {
                                setFocus(true)
                                setAnchorGlobalSearch(parentRef);
                            }}
                            searchOptions={globalSearchOptions}
                            onOptionChange={(option) => {
                                handleSwitchSelectedSearchOption(option);
                                setFocus(true);
                            }}
                            defaultSearchOption={selectedGlobalSearchOption}
                            displayShadow={true}
                        />
                    </SectionPermission>
                </Box> : ''}
        </Box>
    );
}
export default Header;