import './ErrorPage.scss';
import { Box, Typography } from '@material-ui/core';
import logo from '../../assets/Primary-Connect-Plus-Logo.svg';
import png from '../../assets/InPage-Error.png';
import { PageTitle } from '../../models/page-title';

function ErrorPage() {
    document.title = PageTitle.ERRORPAGE;
    return (
        <Box className="error-container" data-testid='ErrorPage'>
            <Box ><img src={logo} className='primary-connect-logo' alt="logo" /></Box>
            <Box><img src={png} className="in-page-error" alt="error" /></Box>
            <Box><Typography variant="h3" component="h3">Something went wrong!</Typography></Box>
            <Box><Typography variant="body1" className="statement-start">We can’t load the results for this page. Please visit the <a href="/"> MyPrimaryConnect Dashboard</a></Typography></Box>
            <Box className="statement-end"><Typography variant="body2">Contact us by phone <a href="tel:1300000000">1800 PRIMARY</a> or email <a href="mailto:support@primaryconnect.com.au">support@primaryconnect.com.au</a></Typography></Box>
        </Box>
    );
}
export default ErrorPage;