import { useMemo } from 'react';
import { Box, Grid, TableCell, TableContainer, TableRow, Typography } from '@material-ui/core';
import { sortBy } from 'lodash';
import { useSelector } from 'react-redux';
import QRCodeCSP from '../QRCode/QRCodeCSP';
import logo from '../../assets/Primary-Connect-Plus-Document-Footer-Logo.svg'
import './Connote.scss';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { Tag } from 'csp-common-ui';
import { FRUITANDVEG } from '../utils/bookingType';
import { OrderStatus } from '../../models/order-status';
import { RoleType } from '../../models/user-roles';
import { selectUserDetails } from '../../store/user/user.selectors';

interface ConnoteProps {
    transportOrder: any,
    transportLeg?: number,
    id?: string
}

export const Connote = ({ transportOrder, transportLeg = 0, id }: ConnoteProps) => {
    const userRoleType = useSelector(selectUserDetails)?.user?.roletype?.toLowerCase() ?? '';
    const sortedArr = useMemo(() => sortBy(transportOrder?.transportOrderLegs, (o) => { return o.loadSeqNum }), [transportOrder?.transportOrderLegs]);
    const CONNOTE_TITLES = ['Commodity', 'Pallets', 'Spaces', 'Carton Qty','Temp(C)', 'Weight (KG)', 'Volume(M3)', 'Probe temp(C)', 'Driver initial'];
    //Subject to change in future
    const QRLink = `${window.location.host}/booking/detail/${transportOrder?.transportOrderId}`;
    //get our table data
    const convertToFormattedDate = (date: string) => {
        //in format DD-MM-YYYY 
        if (date) {
            dayjs.extend(customParseFormat);
            const dayjsObject = dayjs(date, "DD-MM-YYYY");
            return (dayjsObject.format("ddd DD MMM YYYY"));
        }
        return "";
    }

    const totalCartons = () => {
        if(transportOrder && transportOrder?.items) {
            return transportOrder.items.reduce((acc: number, obj: any) => acc + obj.cartonQuantity, 0)
        }
        return 0;
    }

    const commodityArray = [
        {
          type: "Ambient",
          temperature: null,
        },
        {
          type: "Chilled",
          temperature: "-1°C to 5°C",
        },
        {
          type: "Confectionary",
          temperature: "10°C to 18°C",
        },
        {
          type: "Liquor",
          temperature: null,
        },
        {
          type: "Frozen",
          temperature: "≤ -18°C",
        },
        {
          type: "Produce",
          temperature: null,
        }
      ];

      const commodityTemp = () => {
        if(transportOrder) {
            if(transportOrder?.bookingType === FRUITANDVEG) {
                return transportOrder.items?.[0]?.temperature
            } else {
                const obj = commodityArray.find((item)=> item.type === transportOrder?.commodityTypeMdId);
                return obj ? obj.temperature : null;
            }
        }
        return null;
      }


    return (
        <Box id={id} className="connote-container-dashboard" maxWidth='xl' data-testid="connote-container">
            <style type="text/css" media="print">{"\
            @page { size: landscape; }\
            "}</style>
            <Box py={3} p={3}  paddingBottom={0}>
                <Box className="connote-title">
                    <Box pt={3} className="qrcode-align"> <QRCodeCSP link={QRLink} size={90} /></Box>
                    <Grid container>
                        <Grid item xs={12} sm={12}>
                            <Box display="flex" alignItems="center" className='ponumber-align'>
                                <Box display='flex'><Typography variant="subtitle2"><Box className='po-flex-align' display='flex'> Purchase order {transportOrder?.poNumber} &nbsp; <Tag closeAction={false} type={(transportOrder?.orderStatus ?? '').toLowerCase() as any} size='small' /></Box></Typography> </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Box display='flex' className="details-align">
                                <Box className="details-column">
                                    <Box className="display-data">
                                        <table className="details-titles">
                                            <tbody>
                                                <tr className="table-row">
                                                    <td className="table-data"><Typography variant="h3">TB#</Typography></td>
                                                    <td className="table-data"><Typography variant="h4">{transportOrder?.transportOrderId}</Typography></td>
                                                </tr>
                                                <tr className="table-row">
                                                    <td className="table-data"><Typography variant="h3">Customer name</Typography></td>
                                                    <td className="table-data"><Typography variant="h4">{transportOrder?.customerNameMd + ` (${transportOrder?.supplierNumber})`}</Typography></td>
                                                </tr>
                                                <tr className="table-row">
                                                    <td className="table-data"><Typography variant="h3">Sender Ref#</Typography></td>
                                                    <td className="table-data"><Typography variant="h4">{(transportOrder?.customerReference !== '') ? transportOrder?.customerReference : ' '}</Typography></td>
                                                </tr>
                                                <tr className="table-row">
                                                    <td className="table-data"><Typography variant="h3">Collection Date/Time</Typography></td>
                                                    <td className="table-data"><Typography variant="h4">{convertToFormattedDate(transportOrder?.deliveryDetails?.origin.pickUpDate)}, { (transportOrder?.bookingType === FRUITANDVEG ? ((transportOrder?.deliveryDetails?.origin.pickUpTime).slice(6,8)) :transportOrder?.deliveryDetails?.origin.pickUpTime)}</Typography></td>
                                                </tr>
                                                <tr className="table-row">
                                                    <td className="table-data"><Typography variant="h3">Expected Delivery</Typography></td>
                                                    <td className="table-data"><Typography variant="h4" data-testid="date-formatte">{convertToFormattedDate(transportOrder?.deliveryDetails?.destination.deliveryDate)}{transportOrder?.bookingType !== FRUITANDVEG && `, ${transportOrder?.deliveryDetails?.destination.deliveryTime}`}</Typography></td>
                                                </tr>
                                                <tr className="table-row">
                                                    <td className="table-data"><Typography variant="h3">Truck type</Typography></td>
                                                    <td className="table-data"><Typography variant="h4">{transportOrder?.transportOrderLegs[transportLeg]?.truckTypeMdId}</Typography></td>
                                                </tr>
                                                <tr className="table-row">
                                                    <td className="table-data"><Typography variant="h3">Load type</Typography></td>
                                                    <td className="table-data"><Typography variant="h4">{transportOrder?.transportOrderLegs[transportLeg]?.truckLoadTypeMdId}</Typography></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Box>
                                </Box>
                                <Box className="pickup-column" py={4}>
                                    <Box className="pickup-text">
                                        <i className="ri-upload-line"></i>
                                        <Typography variant="h2">Collection</Typography>
                                    </Box>
                                    <Box>
                                        <i className="ri-map-pin-5-line"></i>
                                        <Typography variant="h4">{transportOrder?.deliveryDetails?.origin.locationMdStreetAddress}, {transportOrder?.deliveryDetails?.origin.locationMdCity}, {transportOrder?.deliveryDetails?.origin.locationMdDistrictId} {transportOrder?.deliveryDetails?.origin.locationMdPostcode}</Typography>
                                    </Box>
                                    <Box>
                                        <i className="ri-account-circle-line"></i>
                                        <Typography variant="h4">{transportOrder?.deliveryDetails?.origin.originDetail}</Typography>
                                    </Box>
                                </Box>
                                <Box className="delivery-column" py={4}>
                                    <Box className="pickup-text">
                                        <i className="ri-download-line"></i>
                                        <Typography variant="h2">Delivery</Typography>
                                    </Box>
                                    <Box>
                                        <i className="ri-map-pin-5-line"></i>
                                        <Typography variant="h4">{transportOrder?.deliveryDetails?.destination.locationMdStreetAddress}, {transportOrder?.deliveryDetails?.destination.locationMdCity}, {transportOrder?.deliveryDetails?.destination.locationMdDistrictId} {transportOrder?.deliveryDetails?.destination.locationMdPostcode}</Typography>
                                    </Box>
                                    <Box>
                                        <i className="ri-account-circle-line"></i>
                                        <Typography variant="h4">{transportOrder?.deliveryDetails?.destination.destinationDetail}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>

                <Box className='table-align'>
                    <TableContainer className="table">
                        <TableRow>
                            {CONNOTE_TITLES.map((title) => (
                                <TableCell className="title-cell"><Typography variant="h3">{title}</Typography></TableCell>
                            ))}
                        </TableRow>
                        {transportOrder && <TableRow>
                                <TableCell className="table-cell"><Typography variant="h4">{transportOrder?.commodityTypeMdId}</Typography></TableCell>
                                <TableCell className="table-cell"><Typography variant="h4">{transportOrder?.palletsQuantity}</Typography></TableCell>
                                <TableCell className="table-cell"><Typography variant="h4">{transportOrder?.spacesQuantity}</Typography></TableCell>
                                <TableCell className="table-cell"><Typography variant="h4">{totalCartons()}</Typography></TableCell>
                                <TableCell className="table-cell"><Typography variant="h4">{commodityTemp()}</Typography></TableCell>
                                <TableCell className="table-cell"><Typography variant="h4">{transportOrder?.totalWeight}</Typography></TableCell>
                                <TableCell className="table-cell"><Typography variant="h4">{transportOrder?.totalVolume}</Typography></TableCell>
                                <TableCell className="table-cell"></TableCell>
                                <TableCell className="table-cell"></TableCell>
                            </TableRow>
                        }
                    </TableContainer>
                    <Box className="details">
                        <Box className="top-row">
                            <Box className="top-row-first">
                                <Typography variant="h5">Carrier name</Typography>
                                <Typography align="center" variant="h4">
                                    {
                                        (userRoleType === RoleType.CUSTOMER.toLowerCase() && sortedArr[0]?.firstLeg && sortedArr[0]?.loadStatus !== OrderStatus.SUBMITTED && !!sortedArr[0]?.carrierMdDesc)
                                        ? sortedArr[0]?.carrierMdDesc 
                                        : userRoleType !== RoleType.CUSTOMER.toLowerCase() 
                                        ? transportOrder?.transportOrderLegs[transportLeg].carrierMdDesc 
                                        : ""
                                    }
                                </Typography>
                            </Box>
                            <Box className="top-row-box-driver"><Typography variant="h5">Driver name</Typography></Box>
                            <Box className="top-row-box"><Typography variant="h5">Rego (Truck Registration)</Typography></Box>
                            <Box className="top-row-box"><Typography variant="h5">Trailer number/Container number</Typography></Box>
                        </Box>
                        <Box className="bottom-row">
                            <Box className="bottom-row-first"><Typography variant="h5">Total spaces</Typography></Box>
                            <Box className="bottom-row-box"><Typography variant="h5">Total pallets</Typography></Box>
                            <Box className="bottom-row-box"><Typography variant="h5">Total pallets exchanged</Typography></Box>
                            <Box className="bottom-row-sig">
                                <Typography variant="h5">Carrier signature</Typography>
                                <span>Date</span>
                            </Box>
                            <Box className="bottom-row-sig">
                                <Typography variant="h5">Sender signature</Typography>
                                <span>Date</span>
                                </Box>
                            <Box className="bottom-row-sig">
                                <Typography variant="h5">Receiver signature </Typography>
                                <span>Date</span>
                            </Box>
                        </Box>
                    </Box>
                    <Box className="footer">
                        <img className='primary-connect-logo' src={logo} alt='Primary Connect' />
                        <Typography className="address-line" variant="h5">1 Woolworths Way, Bella Vista NSW 2153  |  PO BOX 8000 Baulkham Hills NSW 2153  |  ABN 88 000 014 675</Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}
export default Connote;
