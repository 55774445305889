import { saveAs } from 'file-saver';
import XLSX from 'xlsx';

export const downloadCSV = (data: any[], fileName: string) => {
    const ws = XLSX.utils.json_to_sheet(data);
    const csv = XLSX.utils.sheet_to_csv(ws);
    const blob = new Blob([csv], {type: 'text/plain;charset=UTF-8'});
    saveAs(blob, fileName);
}


export const saveFromUrl = (url: string, fileName: string) => {
    saveAs(url, fileName);
}

export const wrapAzureUrl = (url: string): string => {
    return `${process.env.REACT_APP_FILE_UPLOAD_URL}/download/?bloburl=${encodeURIComponent(url)}`
}
